import { useState, useEffect, useCallback, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

// Define the shape of your filter state
interface FilterState {
  [key: string]: string | string[]; // Allow for additional dynamic properties
}

// Custom hook to manage filter state and URL sync
export function useFilterState<T extends FilterState>(initialState: T) {
  const history = useHistory();
  const location = useLocation();
  const hasSubmittedRef = useRef(false);

  // Initialize state from URL or default values
  const [filterState, setFilterState] = useState<T>(() => {
    const parsedQuery = queryString.parse(location.search) as Partial<T>;
    return { ...initialState, ...parsedQuery };
  });

  // Update URL when filter state changes
  useEffect(() => {
    if (!hasSubmittedRef.current) return;

    const newQuery: { [key: string]: string | string[] } = {};

    Object.entries(filterState).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          newQuery[key] = value;
        } else {
          delete newQuery[key];
        }
      } else if (value !== "") {
        newQuery[key] = value;
      } else {
        delete newQuery[key];
      }
    });

    const newSearch = queryString.stringify(newQuery);
    if (newSearch !== location.search.slice(1)) {
      history.replace({ ...location, search: newSearch });
    }
  }, [filterState, history, location]);

  const updateFilterState = useCallback((newState: T) => {
    setFilterState(newState);
    hasSubmittedRef.current = true;
  }, []);

  return [filterState, updateFilterState] as const;
}
