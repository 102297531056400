import { FC, useState } from "react";
import cx from "classnames";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { isBefore, parseISO, lightFormat } from "date-fns";
import { NavTitle } from "layouts/PortalLayout/NavTitle";
import { FAIcon } from "components/FAIcon";
import {
  FilterPanel,
  Filter,
  defaultFilter,
  removeVoidKeys,
} from "./FilterPanel";
import { makeAppendItems } from "lib/makeAppendItems";
import { translateLOR } from "lib/kentuckyWording";
import "./WorklistPage.css";
import { Button } from "components/Button";
import {
  useAppointmentRequestsPresence,
  AppointmentRequestsPresenceData,
} from "hooks/useAppointmentRequestsPresence";
import { Avatar } from "components/Avatar";
import { ZoomIn } from "components/Animations";
import { useFilterState } from "hooks/useFilterState";
import { WorklistExportModal } from "./WorklistExportModal/WorklistExportModal";

const PAGE_SIZE = 25;

const REQUESTS_QUERY = gql`
  query GetAppointmentRequests(
    $first: Int!
    $after: String
    $filter: AppointmentRequestsFilter!
  ) {
    appointmentRequests(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        insertedAt
        createdByUser {
          id
          firstName
          lastName
          organization {
            id
            name
          }
        }
        createdByAgent {
          id
          firstName
          lastName
        }
        caseProfiles {
          id
          memberFirstName
          memberLastName
          memberDob
          caseReferenceNumber
          insurancePlanCode
          levelOfReview {
            value
            label
          }
          modality {
            id
            name
          }
          healthPlan {
            id
            name
          }
          memberState {
            id
            name
            abbreviation
          }
        }
        appointments {
          id
          completed
          cancelled
          missed
          requiresReschedule
          startTimeString(format: "{h12}:{m} {am} {Zabbr}")
          timeRange {
            start
            finish
          }
        }
      }
    }
  }
`;

interface Data {
  appointmentRequests: {
    cursor?: string;
    endOfList: boolean;
    items: AppointmentRequest[];
  };
}

type Appointment = {
  id: string;
  completed: boolean;
  cancelled: boolean;
  missed: boolean;
  requiresReschedule: boolean;
  startTimeString: string;
  timeRange: {
    start: string;
    finish: string;
  };
};

type AppointmentRequest = {
  id: string;
  insertedAt: string;
  createdByUser?: {
    id: string;
    firstName: string;
    lastName: string;
    organization: {
      id: string;
      name: string;
    };
  };
  createdByAgent?: {
    id: string;
    firstName: string;
    lastName?: string;
  };
  caseProfiles: {
    id: string;
    memberFirstName: string;
    memberLastName: string;
    memberDob: string;
    caseReferenceNumber: string;
    insurancePlanCode?: string;
    levelOfReview: {
      value: string;
      label: string;
    };
    modality: {
      id: string;
      name: string;
    };
    healthPlan: {
      id: string;
      name: string;
    };
    memberState: {
      id: string;
      name: string;
      abbreviation: string;
    };
  }[];
  appointments: Appointment[];
};

function shouldBeOccuring(appointment: Appointment) {
  const start = parseISO(appointment.timeRange.start);
  const finish = parseISO(appointment.timeRange.finish);
  const now = new Date();
  return isBefore(start, now) && isBefore(now, finish);
}

function shouldBeFinished(appointment: Appointment) {
  const finish = parseISO(appointment.timeRange.finish);
  const now = new Date();
  return isBefore(finish, now);
}

/**
 * AppointmentRequestRow.
 */

interface AppointmentRequestRowProps {
  appointmentRequest: AppointmentRequest;
}

const AppointmentRequestRow: FC<AppointmentRequestRowProps> = (props) => {
  const { appointmentRequest } = props;
  const { caseProfiles, createdByUser, createdByAgent } = appointmentRequest;
  return (
    <Link
      to={`/o/requests/${appointmentRequest.id}`}
      className={cx("WorklistPage__tr", {
        "WorklistPage__tr--is-occurring": shouldBeOccuring(
          appointmentRequest.appointments[0]
        ),
        "WorklistPage__tr--is-finished": shouldBeFinished(
          appointmentRequest.appointments[0]
        ),
      })}
    >
      <div className="WorklistPage__td WorklistPage__td--case_number">
        <p className="WorklistPage__emph">
          {caseProfiles
            .map((caseProfile) => caseProfile.caseReferenceNumber)
            .join(", ")}
        </p>
      </div>
      <div className="WorklistPage__td">
        {caseProfiles.map((caseProfile) => (
          <div key={caseProfile.id}>
            <p className="WorklistPage__emph capitalize">
              {caseProfile.memberFirstName} {caseProfile.memberLastName}
            </p>
            <p className="WorklistPage__sub">DOB: {caseProfile.memberDob}</p>
          </div>
        ))}
      </div>
      <div className="WorklistPage__td WorklistPage__td--date">
        <p className="WorklistPage__emph">
          {lightFormat(
            parseISO(appointmentRequest.appointments[0].timeRange.start),
            "M/d/yy"
          )}
        </p>
        <p className="WorklistPage__sub">
          at {appointmentRequest.appointments[0].startTimeString}
        </p>
      </div>
      <div className="WorklistPage__td WorklistPage__td--state">
        <p className="WorklistPage__emph">
          {caseProfiles[0].memberState.abbreviation}
        </p>
      </div>
      <div className="WorklistPage__td WorklistPage__td--status">
        <Status appointmentRequest={appointmentRequest} />
      </div>
      <div className="WorklistPage__td WorklistPage__td--modality">
        <p className="WorklistPage__emph">{caseProfiles[0].modality.name}</p>
      </div>
      <div className="WorklistPage__td WorklistPage__td--health_plan">
        {caseProfiles.map((caseProfile) => (
          <p key={caseProfile.id} className="WorklistPage__emph">
            {caseProfile.healthPlan.name}
          </p>
        ))}
      </div>
      <div className="WorklistPage__td">
        {createdByUser ? (
          <p>
            {createdByUser.firstName} {createdByUser.lastName}
          </p>
        ) : (
          <p>
            {createdByAgent?.firstName} {createdByAgent?.lastName || null}
          </p>
        )}
      </div>
      {/* <div className="WorklistPage__td">
        <p>{createdByUser.organization.name}</p>
      </div> */}
      <div className="WorklistPage__td">
        <p>
          {translateLOR(
            caseProfiles[0].levelOfReview.label,
            caseProfiles[0].insurancePlanCode,
            caseProfiles[0].memberState.name
          )}
        </p>
      </div>
    </Link>
  );
};

/**
 * NoResults.
 */

const NoResults: FC = () => {
  return (
    <div className="WorklistPage__no-results">
      <div className="WorklistPage__no-results__icon-container">
        <FAIcon icon="search" />
      </div>
      <h1>No matching P2P requests</h1>
    </div>
  );
};

/**
 * Status.
 */

interface StatusProps {
  appointmentRequest: AppointmentRequest;
}

const Status: FC<StatusProps> = (props) => {
  const { appointmentRequest } = props;

  const needsReschedule = !!appointmentRequest.appointments.find(
    (a) => a.requiresReschedule
  );
  const isScheduled = !!appointmentRequest.appointments.find(
    (a) => !a.cancelled && !a.missed && !a.completed
  );
  const isCompleted = !!appointmentRequest.appointments.find(
    (a) => a.completed
  );
  const isCancelled =
    !isCompleted &&
    !isScheduled &&
    !!appointmentRequest.appointments.find((a) => a.cancelled);
  const isMissed =
    !isCancelled &&
    !isScheduled &&
    !!appointmentRequest.appointments.find((a) => a.missed);

  return (
    <div
      className={cx("Status", {
        Status__scheduled: isScheduled,
        Status__completed: isCompleted,
        Status__cancelled: isCancelled || isMissed,
      })}
    >
      <span>
        {isScheduled
          ? "Scheduled"
          : isCompleted
          ? "Completed"
          : isCancelled
          ? "Cancelled"
          : isMissed
          ? "Missed"
          : ""}
      </span>
      {needsReschedule ? (
        <p className="Status__reschedule">
          <span className="icon-container">
            <FAIcon icon="exclamation-triangle" />
          </span>
          Reschedule
        </p>
      ) : null}
    </div>
  );
};

const updateQuery = makeAppendItems<Data>("appointmentRequests");

/**
 * WorklistPage.
 */

interface WorklistPageProps {}

type ActiveModal = { type: "EXPORT"; filter: Partial<Filter> };

export const WorklistPage: FC<WorklistPageProps> = () => {
  const location = useLocation();

  const [enabledFilters, setEnabledFilters] = useState<Array<keyof Filter>>(
    () => {
      const parsedQuery = queryString.parse(location.search) as Partial<Filter>;
      let enabledKeys: Array<keyof Filter> = [];
      const excluded = ["createdAfter", "createdBefore"];
      Object.entries(parsedQuery).forEach(([key, value]) => {
        if (value !== defaultFilter[key] && !excluded.includes(key)) {
          enabledKeys.push(key);
        }
      });
      // Ugly but necessary:
      if (parsedQuery.createdBefore && parsedQuery.createdAfter) {
        enabledKeys.push("dateRange");
      }
      console.log(parsedQuery);
      return enabledKeys;
    }
  );
  const [filter, setFilter] = useFilterState<Filter>(defaultFilter);
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const { data, loading, error, fetchMore } = useQuery<Data>(REQUESTS_QUERY, {
    variables: { first: PAGE_SIZE, filter: removeVoidKeys(filter) },
  });

  const presenceData = useAppointmentRequestsPresence();

  return (
    <div className="_WorklistPage pt-6">
      <WorklistExportModal
        isOpen={activeModal?.type === "EXPORT"}
        filter={activeModal?.filter || {}}
        onClose={() => setActiveModal(null)}
        onSuccess={() => setActiveModal(null)}
      />

      <NavTitle title="P2P Requests" />

      <div className="flex items-end justify-between px-6">
        <div className="flex-grow">
          <FilterPanel
            value={filter}
            onChange={setFilter}
            isLoading={loading}
            enabledFilters={enabledFilters}
            setEnabledFilters={setEnabledFilters}
          />
        </div>
        <div className="flex-shrink-0">
          <Button
            onClick={() =>
              setActiveModal({ type: "EXPORT", filter: removeVoidKeys(filter) })
            }
          >
            <FAIcon icon="file-download" className="mr-2" />
            Export
          </Button>
        </div>
      </div>

      <div className="m-6 bg-white border rounded-lg shadow-lg">
        {error || (!data && !loading) ? (
          <div style={{ padding: "1.5rem" }}>
            <h1>Error Loading</h1>
          </div>
        ) : (
          <div>
            <div className="WorklistPage__table">
              <div className="WorklistPage__thead">
                <div className="WorklistPage__tr">
                  <div className="WorklistPage__td WorklistPage__td--case_number">
                    Case #
                  </div>
                  <div className="WorklistPage__td">Member Name</div>
                  <div className="WorklistPage__td WorklistPage__td--date">
                    Appointment
                  </div>
                  <div className="WorklistPage__td WorklistPage__td--state">
                    State
                  </div>
                  <div className="WorklistPage__td WorklistPage__td--status">
                    Status
                  </div>
                  <div className="WorklistPage__td WorklistPage__td--modality">
                    Modality
                  </div>
                  <div className="WorklistPage__td WorklistPage__td--health_plan">
                    Health Plan
                  </div>
                  <div className="WorklistPage__td">Request By</div>
                  {/* <div className="WorklistPage__td">Requesting Org</div> */}
                  <div className="WorklistPage__td">Level of Review</div>
                  {/* <div className="WorklistPage__td WorklistPage__td--date">
                          Created At
                        </div> */}
                </div>
              </div>
              {loading ? (
                <div className="WorklistPage__tbody">
                  <h1>Loading...</h1>
                </div>
              ) : error || !data?.appointmentRequests ? (
                <div style={{ padding: "1.5rem" }}>
                  <h1>Error Loading</h1>
                </div>
              ) : (
                <div className="WorklistPage__tbody">
                  {data.appointmentRequests.items.length === 0 ? (
                    <>
                      <NoResults />
                      {filter.caseReferenceNumber ? (
                        <div>
                          <button
                            type="button"
                            className="a"
                            onClick={() =>
                              setFilter({
                                ...filter,
                                caseReferenceNumber: "",
                              })
                            }
                          >
                            Clear search term
                          </button>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {data.appointmentRequests.items.map(
                        (request: AppointmentRequest) => (
                          <div className="relative" key={request.id}>
                            <AppointmentRequestRow
                              appointmentRequest={request}
                            />
                            <ViewingUsers
                              className="top-1/2 absolute left-0 transform -translate-x-1/2 -translate-y-1/2"
                              appointmentRequestId={request.id}
                              presenceData={presenceData}
                            />
                          </div>
                        )
                      )}
                    </>
                  )}
                  <div className="WorklistPage__load-more">
                    {!data.appointmentRequests.endOfList ? (
                      <Button
                        type="button"
                        onClick={() =>
                          fetchMore({
                            query: REQUESTS_QUERY,
                            variables: {
                              first: PAGE_SIZE,
                              after: data.appointmentRequests.cursor,
                              filter,
                            },
                            updateQuery,
                          })
                        }
                      >
                        Load More
                      </Button>
                    ) : (
                      <p className="text-gray-500"> End of List</p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

interface ViewingUsersProps {
  appointmentRequestId: string;
  presenceData: AppointmentRequestsPresenceData;
  className?: string;
}

const ViewingUsers: FC<ViewingUsersProps> = (props) => {
  const { presenceData, className = "", appointmentRequestId } = props;

  if (
    !presenceData ||
    !presenceData.openAppointmentRequests[appointmentRequestId]
  ) {
    return null;
  }

  const [user] = presenceData.openAppointmentRequests[appointmentRequestId].map(
    ({ user_id }) => {
      return presenceData.onlineUsers[user_id];
    }
  );

  return (
    <ZoomIn show className={className}>
      <Avatar size={40} firstName={user.first_name} lastName={user.last_name} />
    </ZoomIn>
  );
};
