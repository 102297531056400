import { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { WorklistExportForm } from "./WorklistExportForm";
import { Filter } from "../FilterPanel";

interface WorklistExportModalProps {
  isOpen: boolean;
  filter: Partial<Filter>;
  onClose(): void;
  onSuccess(): void;
}

export const WorklistExportModal: FC<WorklistExportModalProps> = (props) => {
  const { isOpen, filter, onClose } = props;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="WorklistExportModal"
    >
      <ModalHeader
        icon="file-download"
        title="Export P2P Requests"
        onClose={onClose}
      />

      <WorklistExportForm
        onClose={onClose}
        filter={filter}
        initialValues={{}}
      />
    </Modal>
  );
};
