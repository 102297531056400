import { FC, InputHTMLAttributes, ChangeEvent } from "react";
import { ErrorMessage, useField } from "formik";
import { VerticalField, HorizontalField } from "../FieldStructure";

interface StandardOption {
  value: string;
  label: string;
}

interface InputPlainSelectProps {
  value?: string;
  options: StandardOption[];
  isLoading?: boolean;
  inputProps?: InputHTMLAttributes<HTMLSelectElement>;
  className?: string;
  onChange?(newValue: string): void;
  onBlur?(e: any): void;
  onFocus?(e: any): void;
}

export const InputPlainSelect: FC<InputPlainSelectProps> = (props) => {
  const {
    value,
    options,
    onChange,
    onBlur,
    onFocus,
    isLoading = false,
    inputProps = {},
    className = "",
  } = props;

  function handleChange(evt: ChangeEvent<HTMLSelectElement>) {
    if (onChange) onChange(evt.target.value);
  }

  /**
   * NB: `className` can be overridden by `inputProps`.
   */

  return (
    <select
      className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${className}`}
      onChange={handleChange}
      onBlur={onBlur}
      onFocus={onFocus}
      value={value}
      disabled={isLoading}
      {...inputProps}
    >
      {options.map((o) => (
        <option key={o.value} value={o.value}>
          {o.label}
        </option>
      ))}
    </select>
  );
};

interface PlainSelectInputProps<T = StandardOption> {
  id?: string;
  name: string;
  placeholder?: string;
  options: T[];
  disabled?: boolean;
  inputProps?: InputHTMLAttributes<HTMLSelectElement>;
  isLoading?: boolean;
  autoFocus?: boolean;
  className?: string;
  onFocus?(e: any): void;
}

export const PlainSelectInput: FC<PlainSelectInputProps> = (props) => {
  const {
    id,
    name,
    placeholder,
    autoFocus = false,
    className = "",
    inputProps = {},
    options,
    isLoading = false,
    onFocus,
  } = props;

  const [field, meta, helpers] = useField(name);
  const { value, onBlur } = field;
  const { setValue } = helpers;

  return (
    <>
      <InputPlainSelect
        inputProps={{
          id: id || name,
          placeholder,
          name,
          autoFocus,
          ...inputProps,
        }}
        className={
          meta && meta.touched && meta.error
            ? `${className} border border-red-500`
            : className
        }
        value={value}
        options={options}
        onChange={setValue}
        onBlur={onBlur}
        onFocus={onFocus}
        isLoading={isLoading}
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-xs italic text-red-500"
      />
    </>
  );
};

/**
 * PlainSelectField.
 */
interface PlainSelectFieldProps extends PlainSelectInputProps {
  label: string;
  indicateRequired?: boolean;
  indicateOptional?: boolean;
}

export const PlainSelectField: FC<PlainSelectFieldProps> = (props) => {
  const { label, indicateRequired, indicateOptional, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateRequired={indicateRequired}
      indicateOptional={indicateOptional}
    >
      <PlainSelectInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalPlainSelectField: FC<PlainSelectFieldProps> = (
  props
) => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <PlainSelectInput {...rest} />
    </HorizontalField>
  );
};
