import { FC, useState } from "react";
import { Route, Switch, NavLink } from "react-router-dom";
import { startOfWeek, endOfWeek, addDays } from "date-fns";
import { NavTitle } from "layouts/PortalLayout/NavTitle";
import { SystemSchedulingVolume } from "./reports/SystemSchedulingVolume";
import { ProviderSchedulingVolume } from "./reports/ProviderSchedulingVolume";
import { InternalUserProduction } from "./reports/InternalUserProduction";
import { ScheduleAdherence } from "./reports/ScheduleAdherence";
import { BookingDistance } from "./reports/BookingDistance";
import "./AnalyticsPage.css";

import { DateRangeDropdown } from "./DateRangeDropdown";
import { ExportsWidget } from "./ExportsWidget";
import { TimeRange } from "./types";
import { FAIcon } from "components/FAIcon";
import { WebformSubmissionVolume } from "./reports/WebformSubmissionVolume";
import { ClinicalUserGroupProduction } from "./reports/ClinicalUserGroupProduction";

const AnalyticsRoot: FC = () => {
  return (
    <div className="px-4 pt-24 text-center">
      <h1>Select a Report on the Left</h1>
    </div>
  );
};

enum Reports {
  SystemSchedulingVolume = "system_scheduling_volume",
  ProviderSchedulingVolume = "provider_scheduling_volume",
  WebformSubmissionVolume = "webform_submission_volume",
  InternalUserProduction = "internal_user_production",
  ClinicalUserGroupProduction = "clinical_user_group_production",
  ScheduleAdherence = "schedule_adherence",
  BookingDistance = "booking_distance",
}

const startOfLastWeek = startOfWeek(addDays(new Date(), -7));
const endOfLastWeek = endOfWeek(addDays(new Date(), -7));

interface AnalyticsPageProps {}

export const AnalyticsPage: FC<AnalyticsPageProps> = () => {
  const [timeRange, setTimeRange] = useState<TimeRange>({
    start: startOfLastWeek,
    finish: endOfLastWeek,
  });

  return (
    <div className="AnalyticsPage">
      <NavTitle title="Analytics" />
      <div className="cols">
        <div className="col AnalyticsPage__sidebar">
          <div className="AnalyticsPage__sidebar-section-container">
            <h2 className="AnalyticsPage__sidebar-header">Date Range</h2>
            <DateRangeDropdown value={timeRange} onChange={setTimeRange} />
          </div>
          <div className="AnalyticsPage__sidebar-section-container">
            <h2 className="AnalyticsPage__sidebar-header">Reports</h2>
            <ul className="AnalyticsPage__sidebar__reports">
              <NavLink
                to={`/o/analytics/${Reports.SystemSchedulingVolume}`}
                activeClassName="is-active"
              >
                <li>
                  <span className="icon-container">
                    <FAIcon icon="chart-line" />
                  </span>
                  System Scheduling Volume
                </li>
              </NavLink>

              <NavLink
                to={`/o/analytics/${Reports.WebformSubmissionVolume}`}
                activeClassName="is-active"
              >
                <li>
                  <span className="icon-container">
                    <FAIcon icon="chart-line" />
                  </span>
                  Webform Submission Volume
                </li>
              </NavLink>

              <NavLink
                to={`/o/analytics/${Reports.ProviderSchedulingVolume}`}
                activeClassName="is-active"
              >
                <li>
                  <span className="icon-container">
                    <FAIcon icon="chart-bar" />
                  </span>
                  Appointments by Provider
                </li>
              </NavLink>

              <NavLink
                to={`/o/analytics/${Reports.InternalUserProduction}`}
                activeClassName="is-active"
              >
                <li>
                  <span className="icon-container">
                    <FAIcon icon="chart-bar" />
                  </span>
                  Internal User Production
                </li>
              </NavLink>

              <NavLink
                to={`/o/analytics/${Reports.ClinicalUserGroupProduction}`}
                activeClassName="is-active"
              >
                <li>
                  <span className="icon-container">
                    <FAIcon icon="chart-bar" />
                  </span>
                  Clinical Groups Production
                </li>
              </NavLink>

              {/* <NavLink
                to={`/o/analytics/${Reports.ScheduleAdherence}`}
                activeClassName="is-active"
              >
                <li>
                  <span className="icon-container">
                    <FAIcon icon="chart-bar" />
                  </span>
                  Schedule Adherence
                </li>
              </NavLink>

              <NavLink
                to={`/o/analytics/${Reports.BookingDistance}`}
                activeClassName="is-active"
              >
                <li>
                  <span className="icon-container">
                    <FAIcon icon="chart-bar" />
                  </span>
                  Booking Distance
                </li>
              </NavLink> */}
            </ul>
          </div>

          <div className="AnalyticsPage__sidebar-section-container">
            <h2 className="AnalyticsPage__sidebar-header">Exports</h2>
            <ExportsWidget />
          </div>
        </div>

        <div className="col" style={{ minWidth: 0 }}>
          <Switch>
            <Route exact path="/o/analytics">
              <AnalyticsRoot />
            </Route>

            <Route path={`/o/analytics/${Reports.SystemSchedulingVolume}`}>
              <SystemSchedulingVolume timeRange={timeRange} />
            </Route>

            <Route path={`/o/analytics/${Reports.ProviderSchedulingVolume}`}>
              <ProviderSchedulingVolume timeRange={timeRange} />
            </Route>

            <Route path={`/o/analytics/${Reports.WebformSubmissionVolume}`}>
              <WebformSubmissionVolume timeRange={timeRange} />
            </Route>

            <Route path={`/o/analytics/${Reports.InternalUserProduction}`}>
              <InternalUserProduction timeRange={timeRange} />
            </Route>

            <Route path={`/o/analytics/${Reports.ClinicalUserGroupProduction}`}>
              <ClinicalUserGroupProduction timeRange={timeRange} />
            </Route>

            <Route path={`/o/analytics/${Reports.ScheduleAdherence}`}>
              <ScheduleAdherence timeRange={timeRange} />
            </Route>

            <Route path={`/o/analytics/${Reports.BookingDistance}`}>
              <BookingDistance timeRange={timeRange} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};
